<main>
  <div id="main">
      <div id="backtex">
        <div id="backteximage">
        </div>
      </div>
      <div id="container">
        <section>
          <h1 id="khmn-title">
            KH<span class="mon" id="h1mon">門</span> Festival
          </h1>
          <p class="justify-text">The KH<span class="mon">門</span> festival sees students of the ctrl-space seminar showing interventions and performances in public space and on the building site of the upcoming new KHM building on Heumarkt 14.</p>
            
          <p class="justify-text">
            The KH<span class="mon">門</span> festival opens together with the <a href="https://en.khm.de/khmopen_2021/" target="_blank">KHM OPEN</a> on the evening of July 21st, 2021. This page will be under construction until then.
          </p>
        </section>

        <section>
          <div id="map" />
        </section>

        <section>
          {#if artist !== undefined}
            <h2 id="artwork">
              { artist }, <i>{ title }</i>
            </h2>
          {:else}
            <h2>
              { title }
            </h2>
          {/if}

          <div>
            <div class="desc">
              {@html description }
            </div>
            {#if image !== undefined}
            	{#each image as im}
                <img src={im} class="max" />
            	{/each}
            {/if}
          </div>
        </section>

        <section>
<!--           <a class="artist" target="_blank" href=""> -->
          <p class="justify-text">Participants:
            Bidisha Das,
            <a class="artist" target="_blank" href="https://drive.google.com/drive/folders/1oslmeyljWBv4p2WIxtzZv_J7Zx9XN2RU">Cao Bijun</a>,
            <a class="artist" target="_blank" href="https://www.hongjeesoo.com/">Jeesoo Hong</a> & <a class="artist" target="_blank" href="http://hyeyoungsin.info/">Hye Young Sin</a>,
            <a class="artist" target="_blank" href="https://www.instagram.com/uoojey/">Jey Yoo</a>,
            <a class="artist" target="_blank" href="https://vergazova.com/en/">Julia Vergazova</a>,
            <a class="artist" target="_blank" href="https://cargocollective.com/PAGI">Kihuun Park</a>,
            <a class="artist" target="_blank" href="https://naotohieda.com">Naoto Hieda</a>,
            <!--          <a class="artist" target="_blank" href="https://soundcloud.com/swanmeat">Rebecca Beauchamp</a>,-->
            <a class="artist" target="_blank" href="https://www.liutingchun.com/">Ting-Chun Liu</a>.
            <br><br>
            The works on display reflect the difficult conditions for art during the pandemic and are partly very personal reactions to the experience of being locked out, from each other and from the social space KHM. 
The proclamation of the KH<span class="mon">門</span> Festival is the counter-proposal, the ceremonial opening of all spaces to the public. The title is a pun on "KHM". <span class="mon">門</span> is the Chinese and Japanese ideogram for gate/door and is pronounced [mu:n] / moo͞n in Korean, and similarly in many East Asian countries.
          </p>
          
          <p class="justify-text">
             The <a href="https://www.khm.de/surveillant_architectures/">ctrl-space seminar</a> is taught by Christian Sievers. In the summer semester of 2021, phenomena of the corona and climate crises were examined for their potential as raw material for art, using the term "access" as a point of focus. Particular attention was paid to working out new ways of enabling interaction and exchange with the audience. 
          </p>
          
        </section>

        <section>
          <p class="center-text">
            The contents are licensed under
            <a
              target="_blank"
              href="https://creativecommons.org/licenses/by-sa/4.0/"
              >CC BY-SA 4.0</a
            >
            by respective contributors unless license is specified. Design by Studio <a href="https://www.glitches.me/">glitches.me</a>.
          </p>
          <div class="footer">
            <a href="https://www.khm.de/" target="_blank"><img class="logo" src="https://cdn.glitch.com/41e651e6-0154-4910-9ab6-d9dcb70d1e0c%2FLogoGenerator_Schwarz_Digital_L_Var-1.png?v=1625826394509" /></a>
            <p class="toptop">
              <a target="_blank" href="https://www.khm.de/impressum/">Impressum</a> | <a target="_blank" href="https://www.khm.de/datenschutzerklaerung/">Datenschutzerklärung</a> 
            </p>
          </div>
        </section>
      </div>
    </div>
</main>

<style>
  #backtex {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  #backteximage {
    width: 100%;
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-image: url("https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2FKunsthochschuleKoelnMedienLogo-B.png?v=1625781135407");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #map {
    height: 480px;
  }
  img.max {
    width: 100%;
    height: auto;
  }
  .desc {
    margin: 1em 0 1em 0;
  }
  .footer {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  	align-items: flex-end;
    align-content: stretch;
  }
  .logo {
    width: 200px;
    height: auto;
  }
  @media only screen and (max-width: 600px) {
    .logo {
    width: 100px;
    }
  }
  .toptop {
    text-align: right;
    margin-bottom: 0px;
  }
  .artist {
    font-weight: 400;
  }
</style>

<script>
  import { onMount } from "svelte";
  import * as L from "leaflet";
  import { GestureHandling } from "leaflet-gesture-handling";

  // import "leaflet/dist/leaflet.css";
  import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
  
  let map;
  let title = "KH門";
  let artist;
  let image;
  let description = "Please click on a pin for more info!";
  
  const interventions = [
    {
      latlng: [50.93433700739093, 6.962164232581725],
      title: "Urban bower: a slope",
      artist: "Jeesoo Hong and Hye Young Sin",
      desc: `Inspired by a bird which inhabits a city and constructs a nest in urban environment, this installation explores an architectural surplus space and challenges a human-oriented perspective in urban area.`,
      image: "https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2F_I9A6585.jpg?v=1627823783847"
    },
    {
      latlng: [50.933588885810714, 6.961100605010036],
      title: "Floating Utopia Act 3 (various locations/online)",
      artist: "Julia Vergazova and Nikolay Ulyanov",
      desc: `We are considering various options for assembling new utopian scenarios of the world, the producers of which are algorithms and neural networks. The resulting reconstructions are hybrid patches, inhabited by a variety of myths and strange inhabitants - datasets involved in rehearsing the end of the world and <a href="https://yukkalo.github.io/fud3m674/" target="_blank">visualizing its restoration.</a> <br><br> <img class="max" src="https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2Ffloating-utopia-sticker.jpg?v=1626246786765>`,
      image: ["https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2F_I9A6590.jpg?v=1627823785191", "https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2Ffloating-utopia-sticker.jpg?v=1626246786765"]
    },
    {
      latlng: [50.93471591742263, 6.96207270065837],
      title: "Best Practices in Under Construction",
      artist: "Naoto Hieda and Jorge Guevara",
      desc: `Naoto Hieda and Jorge Guevara have been <a href="https://best-practices.glitch.me/" target="_blank">practicing</a> over a year, and for the first #bestpracticesincontemporarydance exhibition, they installed a triptych of their practice featuring variety of <a href="http://www.textfiles.com/underconstruction/" target="_blank">#underconstruction</a> gifs in a construction site.
      <img class="max" src="https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2Funderconstruction.gif?v=1625657820456">
      `,
      image: ["https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2Funderconstruction.jpg?v=1627822787268", "https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2Fnbaustelle.jpg?v=1625779632657"]
    },
    // {
    //   latlng: [50.933356498373605, 6.960235151521613],
    //   title: "room2",
    //   artist: "",
    //   desc: `the base`,
    // },
    //     {
    //   latlng: [50.93356741770545, 6.959630969696853],
    //   title: "no title yet",
    //   artist: "Rebecca Beauchamp",
    //   desc: `description to go here`,
    // },
    {
      latlng: [50.93357878612202, 6.959779361735121],
      title: "Air spam",
      artist: "Air spam, performed by Kihuun Park, Jeesoo Hong, Hyeseon Jeong, and Seongmin Yuk",
      desc: `On 7 July 2021, ten artists presented their artworks on a flight to Korea, in an experimental and performative happening that engaged themes of access and migration. Three video screens document this collaborative work.
      <br><br>The participants were Naoto Hieda, Jeesoo Hong, Christian Sievers, Seongmin Yuk, Kihuun Park, Hyeseon Jung, Artiom Zavadovsch, Jey Yoo, Julia Vergazova and Ting Chun Liu.`,
      image: "https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2Fair_spam_poster.jpg?v=1626348980484"
    },
        {
      latlng: [50.93354723074261, 6.959587425458188],
      title: "Stacking City",
      artist: "Ting-Chun Liu",
      desc: `A stack-based video, capturing the vibration and acceleration of the city in moving image.`,
      image: ["https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2Fcool_aula1.jpg?v=1626267473059",
              "https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2Fkhmn.jpg?v=1627823389810"]
    },
        {
      latlng: [50.93357534900578, 6.95998342909679],
      title: "fly my NaBi",
      artist: "Jey Yoo",
      desc: ``,
    },
    {
      latlng: [50.93427515993709, 6.962745959102543],
      title: "Kundenstopper für Beauty",
      artist: "Cao Bijun",
      desc: `<!-- Two ads - One is for an empty lipstick. This was the first lipstick of Dior, and looks like a crystal obelisk. One is for eyelash growth (Wimperverlängerung) -->`,
      image: ["https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2F_I9A6571.jpg?v=1627823787087", "https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2Fcao1.jpg?v=1626170712923"]
    },
    {
      latlng: [50.933582, 6.960146],
      title: "Blue Box",
      artist: "Cao Bijun",
      desc: `<a href="https://atlas.mindmup.com/bcao/_three_door_/index.html#" target="_blank">https://atlas.mindmup.com/bcao/_three_door_/index.html#</a>
      <!-- Because of the epidemic, many doors were closed. So I will try to open three doors for you and this is my third door. Someone will leave something for you in this box. Just come and find the person who works for the door in Aula, then ask: I want open a Blue box? It will open for you ... -->`,
      image: ["https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2F_I9A6725.jpg?v=1627823786083", "https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2Fcao2.png?v=1626170713141"]
    },
    {
      latlng: [50.933651604508306, 6.963442625291174],
      title: "Von dort nach hier",
      artist: "Kihuun Park",
      desc: `An open call for immigrants to Germany to share their stories`,
      image: "https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2F_I9A6678.jpg?v=1627823785774"
    },
    {
      latlng: [50.934237460772145, 6.95996386965049],
      title: "Hz & Beyond",
      artist: "Bidisha Das",
      desc: `a kinetic installation/performance that explores the sonic world unheard by humans, the inaudible vibrations that often exist around the human habitat`,
      image: ["https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2F_I9A6646.jpg?v=1627823785922", "https://cdn.glitch.com/c872ab9a-264e-4ce2-91db-721811e90193%2Fhz_bidisha.jpg?v=1626272907061"]
    }
    
    
  ]

  onMount(async () => {
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    
    const mbAttr = 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
			'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
		mbUrl = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw';

    const grayscale   = L.tileLayer(mbUrl, {id: 'mapbox/dark-v10', tileSize: 512, zoomOffset: -1, attribution: mbAttr}),
      streets  = L.tileLayer(mbUrl, {id: 'mapbox/streets-v11', tileSize: 512, zoomOffset: -1, attribution: mbAttr});

    const map = L.map('map', {
      center: [50.93426151624613, 6.961927257458063],
      zoom: 17,
      gestureHandling: true,
      layers: [streets]
    });

    const baseLayers = {
      "Grayscale": grayscale,
      "Streets": streets
    };
    
    // map = L.map("map").setView([50.93426151624613, 6.961927257458063], 17);
    // L.tileLayer("https://a.tile.openstreetmap.org/{z}/{x}/{y}.png ", {
    //   attribution:
    //     'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
    //   maxZoom: 18
    // }).addTo(map);
    
    window.gotoArtwork = function () {
      const rect = document.getElementById("artwork").getBoundingClientRect();
      window.scrollTo({
        top: rect.top + window.pageYOffset - 100,
        left: 0,
        behavior: 'smooth'
      });
    }
    
    const polyline = L.polyline([
      [50.934237460772145, 6.95996386965049],
      [50.93519840786988, 6.960469358777715],
      [50.9348062183974, 6.96109165152635],
    ])
    .addTo(map);
    
    for(const p of interventions) {
      let marker = L.marker(p.latlng).addTo(map);
      marker.bindPopup(`${p.title}<br><a class="details" onclick="gotoArtwork()">details</a>`)
      .on('click', function() {
        title = p.title;
        artist = p.artist;
        image = Array.isArray(p.image) ? p.image : [p.image];
        description = p.desc;
      })
    }
    
    
    const h1mon = document.getElementById("h1mon");
    let count = 0;
    setInterval(() => {
      h1mon.innerText = [`門`, `開`][count];
      count = (count + 1) % 2;
    }, 2000)
  });
</script>
